import { LoggedLayoutService } from "@puntaje/shared/cui"
import { Component, OnInit } from "@angular/core"
import { MenuItemBoxData } from "@puntaje/shared/layouts"
import { MenuComponent } from "@puntaje/puntaje/new-modules/shared"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "resultados-evaluacion",
    templateUrl: "./resultados-evaluacion.component.html",
    styleUrls: ["./resultados-evaluacion.component.scss"]
})
export class ResultadosEvaluacionComponent extends MenuComponent implements OnInit {
    menuItems: MenuItemBoxData[] = []
    tipoInstrumento: string

    constructor(
        protected store: Store<State>,
        protected authService: AuthService,
        protected loggedLayoutService: LoggedLayoutService
    ) {
        super(store)
    }

    ngOnInit() {
        this.tipoInstrumento = config.plataforma.evaluacionDefault
        super.ngOnInit()
        this.setMenuItems()

        this.loggedLayoutService.setOptions({ freeContentStyle: true })
    }

    async setMenuItems() {
        const asignaturasByEvaluacionTipo = await this.asignaturasByEvaluacionTipo$.toPromise()

        this.menuItems = [
            {
                route: "/evaluaciones/resultados/historial",
                params: {},
                label: "Historial",
                text: "Encuentra aquí la lista de todas las evaluaciones online, impresas y sin configurar.",
                linkText: "Ver historial",
                icon: "guia"
            },
            {
                route: "/estadisticas",
                params: { tipo_instrumento: this.tipoInstrumento },
                label: "Estadísticas",
                text: "Encuentra aquí estadísticas generadas a partir de los resultados obtenidos en evaluaciones.",
                linkText: "Ver estadísticas",
                icon: "estadistica-o"
            },
            {
                route: "/pruebas/progreso",
                params: { tipo_instrumento: this.tipoInstrumento },
                label: "Progreso de mis estudiantes",
                text: "Observa la evolución en el tiempo de los resultados obtenidos por los estudiantes.",
                linkText: "Ver progreso",
                icon: "estadistica-o"
            }
        ]
    }
}
