import { Component, Input, OnInit } from "@angular/core"
import { AppConfig, AuthService } from "@puntaje/shared/core"
import { Login } from "@puntaje/puntaje/services"
import { Router } from "@angular/router"

@Component({
    selector: "facebook-button",
    templateUrl: "./facebook-button.component.html",
    styleUrls: ["./facebook-button.component.scss"]
})
export class FacebookButtonComponent implements OnInit {
    invalidLogin: boolean = false
    @Input() showIcono = true
    constructor(public loginService: Login, public authService: AuthService, protected router: Router, protected config: AppConfig) {}

    ngOnInit() {}

    loginFacebook() {
        this.authService
            .loginWithFacebook()
            .then(response => {
                this.loginService.loginGeneral(
                    this.authService.loginFacebook(response["id"], response["email"], false),
                    r => {
                        this.router.navigate([this.config.app.paths.pendingInfo])
                    },
                    false,
                    false
                )
            })
            .catch(response => {
                if (response && response.status == 401) {
                    this.invalidLogin = true
                } else {
                    throw new Error("Error " + response.status)
                }
            })
    }
}
