<div class="login-box">
    <h3>Inicia sesión</h3>
    <ng-container *ngIf="!isLoggedIn()">
        <form novalidate [formGroup]="form" (ngSubmit)="login()" id="login-form">
            <div class="mensaje-error" *ngIf="invalidLogin">
                <div *ngIf="invalidLogin"><p [innerHTML]="invalidLoginMessage"></p></div>
                <!--<div *ngIf="checkEmail()"><p>{{ config.mensajesLogin.invalidLoginId }}</p></div>
				<div *ngIf="checkPassword()"><p>Debes incluir una contraseña.</p></div>-->
            </div>
            <div class="login-inputs">
                <div class="form-group">
                    <input
                        type="text"
                        name="email"
                        [(ngModel)]="email"
                        [placeholder]="config.mensajesLogin.idPlaceholder"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="email"
                    />
                    <div id="mensaje-error-email" class="mensaje-error-input" *ngIf="checkEmail()">
                        <p>{{ config.mensajesLogin.invalidLoginId }}</p>
                    </div>
                </div>
                <div class="form-group">
                    <input
                        type="password"
                        name="password"
                        [(ngModel)]="password"
                        placeholder="Contraseña"
                        *ngIf="!isLoggedIn()"
                        class="form-control"
                        formControlName="password"
                    />
                    <div id="mensaje-error-password" class="mensaje-error-input" *ngIf="checkPassword()">
                        <p>Debes incluir una contraseña.</p>
                    </div>
                </div>
            </div>
            <button type="submit" id="btn-ingresar" class="btn-ingresar btn btn_base_1" *ngIf="!isLoggedIn()">
                Ingresar
            </button>
            <div class="facebook-login" *ngIf="enableFacebookLogin">
                <facebook-button></facebook-button>
            </div>
            <div class="google-login" *ngIf="enableGoogleLogin">
                <google-button></google-button>
            </div>

            <div class="row bottom-options">
                <div class="col-md-6 no-right-padding">
                    <div class="pwd-reset" *ngIf="enablePasswordRecovery">
                        <p>
                            ¿Olvidaste tu contraseña?
                            <a [routerLink]="['/usuarios/passwords/forgot' || forgot_password_route]">
                                Haz click aquí.
                            </a>
                        </p>
                    </div>
                </div>
                <div class="col-md-6">
                    <ng-container *ngIf="enableRegister">
                        <button
                            type="button"
                            id="btn-registro"
                            class="btn btn-registro btn_base_1"
                            (click)="goToRegister()"
                        >
                            Regístrate aquí
                        </button>
                    </ng-container>
                </div>
            </div>
        </form>
    </ng-container>
    <ng-container *ngIf="isLoggedIn()">
        <div class="loggedIn-box">
            <div class="row">
                <div class="col-md-6">
                    <cui-button-link [isBlock]="true" class="btn_style btn-home" routerLink="/home">
                        <ogr-icon name="home-o" align="text-top" class="icono"></ogr-icon>
                        &nbsp;Home
                    </cui-button-link>
                </div>
                <div class="col-md-6">
                    <cui-button [isBlock]="true" buttonType="cancel" (click)="logout()" class="btn_style_2">
                        Cerrar sesión
                    </cui-button>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<request-reset-password></request-reset-password>
