import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ViewChildren,
    QueryList,
    EventEmitter,
    Output,
    ChangeDetectorRef,
    AfterViewInit,
    ElementRef,
    OnDestroy,
    HostListener
} from "@angular/core"
import {
    Evaluaciones,
    Evaluacion,
    EvaluacionInstancia,
    EvaluacionInstancias,
    EvaluacionTiempos,
    EvaluacionTiempo
} from "@puntaje/puntaje/api-services"
import {
    Asignatura,
    Asignaturas,
    Preguntas,
    Pregunta,
    Alternativa,
    Instrucciones,
    Instruccion,
    PreguntaView
} from "@puntaje/nebulosa/api-services"
import { EvaluacionInteractivaStatusService } from "./evaluacion_interactiva_status.service"
import { LoadingLayoutComponent } from "@puntaje/shared/layouts"
import { Router } from "@angular/router"
import { PreguntaEnsayoComponent } from "./pregunta_ensayo.component"
import { AuthService, ErroresService } from "@puntaje/shared/core"
import { Store, select } from "@ngrx/store"
import { State, SetActual, selectEvaluacionesSeeingIndex, selectEvaluacionesActual } from "@puntaje/puntaje/store"
import {
    SetSeeingIndex,
    ResetDudaPreguntas,
    SetRespuestasActual,
    Item,
    SetItems,
    MoveSeeingIndex,
    GetItems,
    MoveToNextDuda,
    MoveToPrevDuda,
    CleanRespuestasActual,
    SetDudaPreguntasNoContestadas,
    MoveSeeingIndexToLastRespuesta,
    selectGrupoPreguntaItems,
    selectEvaluacionesItems
} from "@puntaje/puntaje/store"
import { Subscription, fromEvent, combineLatest, empty } from "rxjs"
import { first, switchMap, filter, map, tap, take } from "rxjs/operators"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig
import { RealizarEnsayoService } from "./realizar_ensayo.service"

@Component({
    selector: "evaluacion-secuencial",
    templateUrl: "./evaluacion_secuencial.component.html",
    styleUrls: ["./evaluacion_secuencial.component.scss"]
})
export class EvaluacionSecuencialComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() enablePreguntasId: boolean = true
    @Input() enableReporte: boolean = true
    @Input() evaluacionId: number
    @Input() evaluacionTiempo: EvaluacionTiempo
    @Input() enableBtnMarkAsUncertain: boolean = false
    @Input() isFromPlanPersonal: boolean = false
    @Input() enableDudasBtn: boolean = false
    @Input() completarButton: String = "completar"
    @Input() textoCompletar: String

    restrictedNavigation = config.plataforma?.evaluacionSecuencial?.restrictedNavigation || false
    autoDudaNoContestada = config.plataforma?.evaluacionSecuencial?.autoDudaNoContestada || false

    @ViewChild(LoadingLayoutComponent) loadingLayout: LoadingLayoutComponent
    @ViewChildren("preguntaEnsayo", { read: PreguntaEnsayoComponent })
    preguntaEnsayoComponent: QueryList<PreguntaEnsayoComponent>
    // @ViewChild("nextDuda") nextDudaButton: ElementRef
    // @ViewChild("prevDuda") prevDudaButton: ElementRef
    // nextDudaSub: Subscription
    // prevDudaSub: Subscription

    @Output() onReadyEvaluacion: EventEmitter<any> = new EventEmitter<any>()
    @Output() onLoadClockChange: EventEmitter<any> = new EventEmitter<any>()
    @Output() evaluacionEntregada: EventEmitter<any> = new EventEmitter<any>()

    evaluacion: Evaluacion
    asignatura: Asignatura

    preguntas: Pregunta[]

    preguntaIds: number[]
    contestableIds: number[]
    alternativaIds: number[]
    tiempos: number[]
    pistaVistas: boolean[]
    alternativasEscogidas: any[]

    loading: boolean = true
    displayPreguntas: boolean = false

    tiempoInicial: Date

    indexActual: number = 0

    evaluacionSecuencialLocal: any
    showInstruccion: boolean = true
    currentInstruccion: Instruccion
    arrayPreguntaEnsayoComponent: any

    showDudaButtons: boolean = false

    onlyCB: boolean = config.plataforma.name == "College Board"
    onlySI: boolean = config.plataforma.name == "Sanisidoro"

    seeingIndex$ = this.store.pipe(select(selectEvaluacionesSeeingIndex))
    currentItem$ = combineLatest(
        this.seeingIndex$,
        this.store.pipe(
            select(selectEvaluacionesItems),
            filter(items => items.length != 0)
        )
    ).pipe(map(([seeingIndex, items]) => items[seeingIndex]))
    items$ = this.store.pipe(
        select(selectEvaluacionesItems),
        filter(x => x.length != 0 && !!this.evaluacion),
        tap(items => {
            this.items = items

            this.contestableIds = items
                .filter(item => item.tipo == "pregunta")
                .map((item: any) => item.item.contestables[0].id)

            this.loading = false
            this.loadingLayout.ready()
            this.realizarEnsayoService.onReady()
            this.displayPreguntas = true
        })
    )

    grupoPreguntaItems$ = this.store.select(selectGrupoPreguntaItems)

    items: Item[]

    seeingIndexSubscription: Subscription
    dudasSubscription: Subscription

    leftKeySubscription: Subscription
    rightKeySubscription: Subscription

    keypressSubscription: Subscription

    lastStep: boolean = false

    evaluacionSub: Subscription
    el: HTMLElement

    constructor(
        protected router: Router,
        protected evaluacionesService: Evaluaciones,
        protected asignaturasService: Asignaturas,
        protected preguntasService: Preguntas,
        protected instruccionesService: Instrucciones,
        protected evaluacionInstanciasService: EvaluacionInstancias,
        protected evaluacionTiemposService: EvaluacionTiempos,
        protected evaluacionInteractivaStatusService: EvaluacionInteractivaStatusService,
        protected authService: AuthService,
        protected store: Store<State>,
        protected cdr: ChangeDetectorRef,
        protected errores: ErroresService,
        protected realizarEnsayoService: RealizarEnsayoService,
        el: ElementRef
    ) {
        this.el = el.nativeElement
    }

    ngOnInit() {
        this.evaluacionInteractivaStatusService.resetStatus()
        this.realizarEnsayoService.setEntregarEnsayo(this.entregarEnsayo.bind(this))
        this.realizarEnsayoService.load(this.evaluacionId)

        this.evaluacionSub = this.store
            .pipe(
                select(selectEvaluacionesActual),
                filter(e => !e),
                switchMap(() => this.realizarEnsayoService.evaluacion$)
            )
            .subscribe(evaluacion => {
                this.evaluacion = evaluacion
                this.onReadyEvaluacion.emit(evaluacion)
            })

        this.leftKeySubscription = this.currentItem$
            .pipe(
                switchMap(item => (!item.first ? fromEvent<KeyboardEvent>(document, "keydown") : empty())),
                filter((event: KeyboardEvent) => event.code == "ArrowLeft")
            )
            .subscribe(this.prev.bind(this))

        this.rightKeySubscription = this.currentItem$
            .pipe(
                switchMap(item => (!item.last ? fromEvent<KeyboardEvent>(document, "keydown") : empty())),
                filter((event: KeyboardEvent) => event.code == "ArrowRight")
            )
            .subscribe(this.next.bind(this))
    }

    ngAfterViewInit() {}

    setDudaNoContestadas() {
        if (this.autoDudaNoContestada) {
            this.store.dispatch(new SetDudaPreguntasNoContestadas())
        }
    }

    moveToLastRespuesta() {
        this.store.dispatch(new MoveSeeingIndexToLastRespuesta())
    }

    async next() {
        if (this.restrictedNavigation) {
            const dudasCount = this.items.filter(i => i.duda).length
            const lastDudaIndex = this.items.map(i => i.duda).lastIndexOf(true)
            const index = await this.seeingIndex$.pipe(take(1)).toPromise()
            const lastRespuestaIndex = this.alternativaIds
                .map(respuesta => respuesta && (!Array.isArray(respuesta) || respuesta.some(r => r)))
                .lastIndexOf(true)
            if (dudasCount > 0 && index < lastDudaIndex) {
                this.nextDuda()
            } else if (index >= lastRespuestaIndex) {
                this.store.dispatch(new MoveSeeingIndex(1))
                this.setDudaNoContestadas()
            } else {
                this.moveToLastRespuesta()
            }
            return
        }
        this.store.dispatch(new MoveSeeingIndex(1))
        this.setDudaNoContestadas()
        this.focusContent()
    }

    prev() {
        if (this.restrictedNavigation) {
            this.prevDuda()
            return
        }
        this.store.dispatch(new MoveSeeingIndex(-1))
        this.setDudaNoContestadas()
        this.focusContent()
    }

    nextDuda() {
        this.store.dispatch(new MoveToNextDuda())
        this.focusContent()
        this.setDudaNoContestadas()
    }

    prevDuda() {
        this.store.dispatch(new MoveToPrevDuda())
        this.focusContent()
        this.setDudaNoContestadas()
    }

    focusContent() {
        //pone el focus en el contenido actual
        setTimeout(() => {
            let p = this.el.getElementsByClassName("current-content")
            if (p && p.length > 0 && p[0]) {
                let f = p[0].querySelectorAll(
                    'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
                )
                if (f && f.length > 0 && f[0]) {
                    ;(f[0] as HTMLElement).focus()
                }
            }
        }, 200)
    }

    entregarEnsayo() {
        this.loadingLayout.standby()

        this.realizarEnsayoService
            .entregarEnsayo(true, this.isFromPlanPersonal)
            .then((ei: EvaluacionInstancia) => {
                this.evaluacionEntregada.emit(ei)
            })
            .catch(response => {
                this.evaluacionInstanciasService.disableIgnoreCatch()
                if (response && response.status == 422) {
                    this.loadingLayout.ready()
                } else {
                    this.errores.setResponse(response).then(val => {
                        this.router.navigate(["errores"])
                    })
                }
            })
    }

    clearStorage() {}

    cancelLastStep() {
        this.prev()
        this.showDudaButtons = true
    }

    ngOnDestroy(): void {
        this.leftKeySubscription.unsubscribe()
        this.rightKeySubscription.unsubscribe()

        this.realizarEnsayoService.setEntregarEnsayo(null)
        this.evaluacionSub.unsubscribe()
    }

    @HostListener("document:keydown", ["$event"])
    public onKeyDown(event: any): void {
        if (event.code == "ArrowRight" || event.code == "ArrowLeft") {
            event.preventDefault()
        }
    }
}
