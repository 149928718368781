export const esCO = {
    landing: {
        login: {
            user: "Email o TI/CC",
            error_user: "Debes incluir un correo electrónico o TI/CC válido.",
            error_auth1:
                "<strong>La combinación de correo electrónico/contraseña o TI-CC/contraseña es incorrecta.</strong> Las contraseñas de ",
            error_auth2:
                ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.'
        }
    },

    home: {
        showcasebox: {
            grupo_usuarios: {
                soy_docente: "Niveles donde soy Docente",
                ir_cursos: "Ir a Mis Niveles"
            }
        }
    },

    estadisticas: {
        graficos: {
            dtp: {
                not_found: "*No se encuentran estudiantes en este rango",
                title: "Estudiantes por rango"
            }
        }
    },
    progreso: {
        estadisticas: {
            graficos_asignatura: {
                distr_tramos: {
                    descripcion:
                        "Cantidad de evaluaciones realizadas, ordenadas por rangos de %{escala} de acuerdo a los resultados obtenidos en cada una de ellas.",
                    description_with_ranges:
                        "Cantidad de evaluaciones realizadas, ordenadas por rangos de %{escala} de acuerdo a los resultados obtenidos en cada una de ellas. Rangos: %{rangos}.",
                    eje_x: "Rangos de %{escala}",
                    leyenda: "Cantidad de evaluaciones por rango",
                    titulo: "Distribución de resultados por rango de "
                }
            }
        }
    }
}
