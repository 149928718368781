import { Component, Input, OnInit, Optional } from "@angular/core"
import { LoginBaseComponent } from "./login_base/login_base.component"
import { UntypedFormBuilder } from "@angular/forms"
import { Router } from "@angular/router"
import { AuthService, SessionService } from "@puntaje/shared/core"
import { ReporteRazones, NebuAuthService } from "@puntaje/nebulosa/api-services"
import { Usuarios } from "@puntaje/puntaje/api-services"
import { Login } from "@puntaje/puntaje/services"
import { SimpleModalService } from "@puntaje/shared/layouts"
import { Store } from "@ngrx/store"
import { State } from "@puntaje/puntaje/store"
import { filter } from "rxjs/operators"
import { ModalSelectTipoEnvioComponent } from "../registrations/modales/modal-select-tipo-envio/modal-select-tipo-envio.component"
import { CarrerasAuthService } from "@puntaje/carreras/api-services"
import { HttpClient } from "@angular/common/http"
import { I18nService } from "@puntaje/shared/core"

@Component({
    selector: "login-puntaje",
    templateUrl: "login_puntaje.component.html",
    styleUrls: ["login_puntaje.component.scss"]
})
export class LoginPuntajeComponent extends LoginBaseComponent implements OnInit {
    lookedLikeRut = false

    @Input() disableFormatRut = false

    constructor(
        formBuilder: UntypedFormBuilder,
        authService: AuthService,
        sessionService: SessionService,
        nebuAuthService: NebuAuthService,
        reporteRazonesService: ReporteRazones,
        usuariosService: Usuarios,
        simpleModalService: SimpleModalService,
        store: Store<State>,
        router: Router,
        @Optional() carrerasAuthService: CarrerasAuthService,
        loginService: Login,
        http: HttpClient,
        i18nService: I18nService
    ) {
        super(
            formBuilder,
            authService,
            sessionService,
            nebuAuthService,
            reporteRazonesService,
            usuariosService,
            simpleModalService,
            store,
            router,
            carrerasAuthService,
            loginService,
            http,
            i18nService
        )
    }

    ngOnInit() {
        this.form.controls.email.valueChanges.pipe(filter(x => !!x && x.length > 5)).subscribe((value: string) => {
            if (this.disableFormatRut) return

            if (this.lookedLikeRut) value = value.replace(/-/g, "")
            if (this.looksLikeRut(value)) {
                this.lookedLikeRut = true
                const almostLast = value.length - 1
                value = value.substring(0, almostLast) + "-" + value.substring(almostLast)
                this.form.controls.email.setValue(value, { emitEvent: false })
            } else if (this.lookedLikeRut) {
                this.lookedLikeRut = false
                this.form.controls.email.setValue(value, { emitEvent: false })
            }
        })
    }

    public looksLikeRut(value: string) {
        const RUT_REGEXP = /^([0-9kK]{6,14})$/i
        const parts = RUT_REGEXP.exec(value)
        return parts
    }
}
